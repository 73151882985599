import Logo from '@components/common/logo'

const CodingalLogo = () => {
  return (
    <Logo
      withText
      className="h-8 lg:w-40 mt-2 lg:-mt-5 lg:h-12 lg:ml-20 mx-auto"
      alt="Codingal logo"
    />
  )
}
export default CodingalLogo
